<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <upload-files :visible="cpFiles.showDialog" :file_type="cpFiles.file_type" :route_type="cpFiles.route_type" :caller="caller" @upload-done="uploadDone" @close="cpFiles.showDialog=false"></upload-files>

        <div class="pa-3">
            <v-toolbar>
            <v-toolbar-title>{{this.form.fu_name}}>{{this.form.ws_name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
            </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
        </v-card-title>
        <v-card-text>

        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-text>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="created_date"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>
//import axios from 'axios';
import DlgMessage from '@/components/DlgMessage'
import UploadFiles from "@/components/UploadFiles";
import ApFileUploadService from "@/services/ApFileUploadService"
export default {
    components:{DlgMessage,UploadFiles},
    props: {
       caller: String
    },
    data() {
        return{
            //breadcrumb
            items: [
              {text:'File Upload',disabled: false,href:'/notification',},
              {text:'Worksheet',disabled: false,href:'#',},
              {text:'Preview',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Name", value:"fu_name" },
                { text:"File Name", value:"file_name" },
                { text:"Process Status", value:"process_status" },
                { text:"Upload Date", value:"created_date" },
                { text:"Upload By", value:"created_by" },
            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,
            errorFlag : false,
            visible:false,
            routeType:"",
            form:{
                fu_name:"",
                ws_name:'',
            },
            cpFiles:{
                file_type:'excel',
                route_type:'member_registration',
                showDialog:false,
            },
            routeParam:[],

        }
    },
    async created() {  
        this.routeParam['file_upload_id'] = this.$route.params.file_upload_id;
        this.routeParam['route_type'] = this.$route.params.route_type;
        this.routeParam['file_upload_template_id'] = this.$route.params.file_upload_template_id;
        this.routeParam['ws_index'] = this.$route.params.ws_index;
        this.refreshDataTable();
    },
    mounted(){
        this.refreshDataTable();
    },
    methods:{
            async refreshDataTable(){
                let request = {};
                request['login_id'] = "";
                request['file_upload_id'] = this.routeParam['file_upload_id'];
                request['route_type'] = this.routeParam['route_type'];
                request['file_upload_template_id'] = this.routeParam['file_upload_template_id'];
                request['worksheet_index'] = this.routeParam.ws_index;

                try{
                    ApFileUploadService.preview_file_upload(request).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.form.fu_name = res.data.fu_name;
                        this.form.ws_name = res.data.ws_name;
                        if(res.data.data.length>0){
                            this.tblHeaders = [];
                            let keys = Object.keys(res.data.data[0]);
                            for(let key=0;key<=keys.length;key++){
                                let tmp = {text:keys[key],value:keys[key]}
                                this.tblHeaders.push(tmp);
                            }
                        }
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log('e content:',e);
                        this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet Preview',e);
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','Worksheet Preview','Unknown Error');
                  
                }           
            },
			openDetail(obj){
				console.log(obj.file_upload_id)
				this.$router.push({ name: 'fileupload-details', 
					params: { 
						file_upload_id: obj.file_upload_id,
					} 
				})

			},

            addRecord(){
                this.visible = true;
            },
            dlgClose(){
                this.visible = false;
                if(!this.errorFlag)
                    this.refreshDataTable();
            },
            showUpload(uploadType){
                console.log('showupload');
                this.cpFiles.file_type = 'excel';
                this.cpFiles.route_type = this.routeType;
                this.caller = uploadType;
                //console.log(uploadType)
                this.cpFiles.showDialog = true;
            },
            uploadDone(e){
                this.form.file_url = e.fileUrl;
                // console.log("this." + e.caller + "='" + e.fileName + "'");
                // eval("this." + e.caller + "='" + e.fileName + "'");
                this.refreshDataTable();

            },
    }

}
</script>